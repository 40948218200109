import AdminList from './Components/AdminList';
import { List as BillingList, View as BillingView } from './Components/Billing';
import FilterCompanies from './Components/FilterCompanies';
import IdentityDetails from './Components/Identities/Details';
import Users from './Components/Users';
import UserDetails from './Components/Users/Details';

const routes = [
  {
    path: '/users',
    component: Users,
    label: 'Users',
    exact: true,
  },
  {
    path: '/users/:uid',
    component: UserDetails,
    exact: true,
  },
  {
    path: '/identities/:id',
    component: IdentityDetails,
    exact: true,
  },
  {
    path: '/billing/:uid',
    component: BillingView,
    exact: true,
  },
  {
    path: '/billing/',
    component: BillingList,
    exact: true,
    label: 'Billing',
  },
  {
    path: '/admins/',
    component: AdminList,
    exact: true,
    label: 'Admins',
  },
  {
    path: '/filter-companies/',
    component: FilterCompanies,
    exact: true,
    label: 'Filter Companies',
  },
];

export default routes;
