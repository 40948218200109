import { makeStyles } from '@material-ui/core';

import { toPx } from '../../lib/utils';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    padding: theme.spacing(3, 5, 3, 4.375),
    background: theme.palette.primary.white,
    fontFamily: 'sans-serif',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: toPx(theme.spacing(2.5)),
  },
  title: {
    fontSize: toPx(theme.spacing(2.25)),
    fontWeight: 600,
    lineHeight: toPx(theme.spacing(3.75)),
    color: theme.palette.primary.black,
    margin: theme.spacing(0),
  },
  inputSearch: {
    minWidth: 280,
  },
  table: {
    minWidth: 650,
  },
  rootDrawer: {
    width: 420,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.primary.paleGray}`,
  },
  titleDrawer: {
    fontFamily: 'sans-serif',
    fontSize: toPx(theme.spacing(2.5)),
    fontWeight: 600,
    lineHeight: toPx(theme.spacing(3.75)),
    textTransform: 'capitalize',
    color: theme.palette.primary.black,
    margin: theme.spacing(0),
  },
  containerDrawer: {
    width: '100%',
    padding: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    padding: theme.spacing(1, 3),
    borderTop: `1px solid ${theme.palette.primary.paleGray}`,
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: toPx(theme.spacing(2)),
    fontWeight: 500,
    lineHeight: toPx(theme.spacing(3)),
  },
}));
