import { TextField } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';

import EnhancedTable from '../ui/EnhancedTable';
import FilterCompaniesDrawer from './FilterCompaniesDrawer';
import { useFilterCompanies } from './FilterCompanies.hooks';
import useDebounce from '../../hooks/useDebouncer';
import { useStyles } from './styles';

const columns = [
  {
    id: 'key',
    label: 'Name',
  },
  { id: 'updateAt', label: 'Updated At', type: 'date' },
];

function FilterCompanies() {
  const classes = useStyles();
  const {
    isLoading,
    isSubmit,
    companies,
    search,
    selectCompany,
    handleGetFilterCompanies,
    handleSearchChange,
    handleSelectCompanyChange,
    handleSubmitChangeFilterCompany,
  } = useFilterCompanies();

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    handleGetFilterCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const data = useMemo(() => {
    if (!companies.length) return [];
    if (!debounceSearch) return companies;

    return companies.filter((company) => company.key.includes(debounceSearch));
  }, [companies, debounceSearch]);

  return (
    <div className={`${classes.root}`}>
      <div className={classes.container}>
        <h1 className={classes.title}>Filter Companies</h1>
        <TextField
          classes={{ root: classes.inputSearch }}
          variant="outlined"
          size="small"
          placeholder="Search"
          disabled={isLoading}
          value={search}
          onChange={(e) => handleSearchChange(e.target.value)}
        />
        <EnhancedTable
          columns={columns}
          data={data}
          isLoading={isLoading}
          onRowClick={handleSelectCompanyChange}
        />
      </div>
      {!!selectCompany && (
        <FilterCompaniesDrawer
          isSubmit={isSubmit}
          company={selectCompany}
          onClose={() => handleSelectCompanyChange(null)}
          onSubmit={handleSubmitChangeFilterCompany}
        />
      )}
    </div>
  );
}

export default FilterCompanies;
