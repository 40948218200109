import React from 'react';
import CircularProgress from 'material-ui/CircularProgress';

const LoadingComponent = ({ style }) => (
  <div
    style={{
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      ...style,
    }}
  >
    <CircularProgress size={60} thickness={7} />
  </div>
);

export default LoadingComponent;
