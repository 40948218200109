import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as urlList from 'mirage-components/dist/lib/urlList';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import { useStyles } from './styles';

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

FilterCompaniesDrawer.propTypes = {
  isSubmit: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    key: PropTypes.string.isRequired,
    supportEmail: PropTypes.string.isRequired,
    updateAt: PropTypes.string.isRequired,
    whiteListDefault: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default function FilterCompaniesDrawer({
  isSubmit,
  company,
  onClose,
  onSubmit,
}) {
  const classes = useStyles();
  const [defaultData, setDefaultData] = useState(JSON.stringify(company));
  const [formData, setFormData] = useState(company);
  const [error, setError] = React.useState('');

  const handleEmailChange = (supportEmail = '') => {
    setFormData((pre) => ({ ...pre, supportEmail }));
  };

  const handleWhiteListChange = (val = '') => {
    const { value, error: errorCleanVal } = urlList.cleanValues(val);
    setFormData((pre) => ({
      ...pre,
      whiteListDefault: urlList.cleanValues(value).value,
    }));
    setError(errorCleanVal);
  };

  const handleSubmit = () => {
    if (!!onSubmit && !error && !disableSubmit && !isSubmit) {
      onSubmit(formData);
      setDefaultData(JSON.stringify(formData));
    }
  };

  const validateEmail = !EMAIL_REGEX.test(formData.supportEmail);

  const disableSubmit = useMemo(() => {
    return defaultData === JSON.stringify(formData) || validateEmail;
  }, [defaultData, formData, validateEmail]);

  if (!company) return null;

  return (
    <>
      <Drawer anchor="right" open onClose={onClose}>
        <div className={classes.rootDrawer}>
          <div className={classes.header}>
            <h1 className={classes.titleDrawer}>{`${
              company && company.key
            } Default Whitelist`}</h1>
            <IconButton onClick={onClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.containerDrawer}>
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Filter Company Name"
              disabled
              value={company.key}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Support Email"
              required
              value={formData.supportEmail}
              onChange={(e) => handleEmailChange(e.target.value)}
              error={!!validateEmail}
              helperText={validateEmail ? 'Invalid Support Email' : ''}
            />
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              label="Whitelist Websites"
              multiline
              minRows={4}
              maxRows={10}
              value={urlList.formatValues(formData.whiteListDefault)}
              onChange={(e) => handleWhiteListChange(e.target.value)}
              error={!!error}
              helperText={error || ''}
            />
          </div>
          <div className={classes.actions}>
            <Button
              color="secondary"
              className={classes.action}
              onClick={onClose}
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              className={classes.action}
              disabled={disableSubmit || !!error || isSubmit}
              onClick={handleSubmit}
            >
              SAVE
              {isSubmit && (
                <CircularProgress style={{ marginLeft: 8 }} size={16} />
              )}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
