import { useState } from 'react';
import {
  getFilterCompany,
  updateFilterCompany,
} from 'mirage-api-client/dist/browser_settings';
import { useSnackBar } from 'mirage-components/dist/SnackBar';

const DEFAULT_FILER_COMPANIES = [
  {
    key: 'meshimer',
    supportEmail: '',
    updateAt: undefined,
    whiteListDefault: '',
  },
];

function useFilterCompanies() {
  const { newSnackBar } = useSnackBar();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [companies, setCompanies] = useState(DEFAULT_FILER_COMPANIES);
  const [search, setSearch] = useState('');
  const [selectCompany, setSelectCompany] = useState(null);

  const handleGetFilterCompanies = async () => {
    try {
      setIsLoading(true);
      const response = await getFilterCompany();
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.length
      ) {
        setCompanies(response.data.data);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (val) => {
    setSearch(val);
  };

  const handleSelectCompanyChange = (val) => {
    setSelectCompany(val);
  };

  const handleSubmitChangeFilterCompany = async (val) => {
    try {
      setIsSubmit(true);
      const response = await updateFilterCompany(val);
      if (response.status === 200) {
        const newCompanies = companies.map((company) => {
          if (company.key === val.key) {
            return val;
          }
          return company;
        });
        setCompanies(newCompanies);
        newSnackBar({
          message: 'Filter Company Updated Successfully',
          type: 'success',
          position: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      }
    } catch (e) {
      newSnackBar({
        message: 'Filter Company Update Failed',
        type: 'error',
        position: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      setIsSubmit(false);
    }
  };

  return {
    isLoading,
    isSubmit,
    search,
    companies,
    selectCompany,
    handleGetFilterCompanies,
    handleSearchChange,
    handleSelectCompanyChange,
    handleSubmitChangeFilterCompany,
  };
}

export { useFilterCompanies };
