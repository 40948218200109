import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import EnhancedTableHead from './EnhancedTableHead';
import { ORDER_TYPE, getComparator, stableSort } from './EnhancedTable.helper';

const useStyles = makeStyles({
  paper: {
    width: '100%',
  },
  center: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hover: {
    cursor: 'pointer',
  },
});

EnhancedTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['string', 'date']),
      rowStyles: PropTypes.object,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
};

export default function EnhancedTable({
  columns = [],
  data = [],
  isLoading,
  onRowClick,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState(ORDER_TYPE.ASC);
  const [orderBy, setOrderBy] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === ORDER_TYPE.ASC;
    setOrder(isAsc ? ORDER_TYPE.DESC : ORDER_TYPE.ASC);
    setOrderBy(property);
  };

  const handleClick = (_event, row) => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = useMemo(() => {
    return (
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage)
    );
  }, [data, page, rowsPerPage]);

  const rows = useMemo(() => {
    return stableSort(data, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [data, order, orderBy, page, rowsPerPage]);

  return (
    <Paper className={classes.paper}>
      <Table>
        <EnhancedTableHead
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {isLoading || !data.length ? (
            <TableRow style={{ height: 52 * emptyRows }}>
              <TableCell colSpan={columns.length}>
                <div className={classes.center}>
                  {isLoading ? <CircularProgress /> : <span>No Data</span>}
                </div>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {rows.map((row, index) => {
                return (
                  <TableRow
                    onClick={(event) => handleClick(event, row)}
                    key={index}
                    className={classes.hover}
                  >
                    {columns.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        style={{ ...headCell.rowStyles }}
                      >
                        {headCell.type === 'date'
                          ? new Date(row[headCell.id]).toLocaleString()
                          : row[headCell.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 52 * emptyRows }}>
                  <TableCell colSpan={columns.length} />
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
